import React, { useEffect } from 'react'
import 'twin.macro'

import tw from 'twin.macro'

import SEO from 'components/shared/seo'
import useInstallerRegistration from 'content-queries/mx/new/installer-registration'
import fireEvent from 'context/tracking/events'
import {
  ContentTwoColumn,
  FullWidthContent,
  MainContent,
} from 'mx/components/new'
import Layout from 'mx/layout'

const InstallerRegistrationPage = ({ path }: { path: string }) => {
  const { data } = useInstallerRegistration()
  useEffect(() => {
    fireEvent({
      type: 'page_viewed',
      pageTitle: 'Installer Registration',
    })
  }, [])

  const [
    {
      node: {
        sectionModules: [hero, description, important, questions],
      },
    },
  ] = data

  return (
    <Layout path={path}>
      <MainContent header data={hero} />
      <FullWidthContent data={description} classname={tw`lg:mt-12`} />
      <FullWidthContent
        data={important}
        dark
        classname={tw`lg:mt-12 !bg-[#282A2D]`}
      />
      <ContentTwoColumn data={questions} />
    </Layout>
  )
}

export default InstallerRegistrationPage

export const Head = () => {
  const { data } = useInstallerRegistration()
  const [
    {
      node: {
        image,
        meta: { title, description },
      },
    },
  ] = data
  return (
    <SEO
      description={description}
      path="/installer-registration"
      title={title}
      image={
        image.localFile?.childImageSharp?.gatsbyImageData?.images?.fallback?.src
      }
    />
  )
}
